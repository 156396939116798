import { useState, useEffect } from 'react'
import { Button }              from 'antd'
import classNames              from 'classnames'

import { Spinner, Icons }     from 'components'
import { t, adjust, request } from 'tools'
import config                 from 'config'

import ContactsTypePopup from './ContactsTypePopup'
import EditPopup         from './EditPopup'

const Contacts = ({ data, id, editable, types, contacts, shared, updateCard, updateContact, createContact }) => {
    const
        [ edit, $edit ] = useState( null ),
        [ editPopup, $editPopup ] = useState( false ),
        [ editVal, $editVal ] = useState( null ),
        [ popup, $popup ] = useState( false ),
        [ create, $create ] = useState( null ),
        [ saving, $saving ] = useState( null ),
        [ singleLoading, $singleLoading ] = useState( false ),
        [ opening, $opening ] = useState( false ),

        addContact = () => {
            $popup( true )
        },

        addDraft = type => {
            $editPopup( true )
            $create( type )
            $editVal({ type })
        },

        getLogo = ( item, company ) => {
            if ( item.logo ) { return `${config.api.url}/media/${item.logo}` }
            if ( item.type === 'email' && !item.domain && !item.domain?.company ) { return <Icons.Mail /> }
            if ( item.type === 'phone' ) { return <Icons.Phone /> }
            if ( !item.domain || !item.domain?.company ) { return <Icons.Link /> }

            const
                raw  = item.domain.company.square_logo,
                test = raw.slice( 0, 4 ).toLowerCase(),
                logo = test !== 'data' && test !== 'http' && test[ 0 ] !== '/'
                    ? `${config.api.url}/media/${raw}`
                    : raw

            return <img src={logo} alt={company} />
        },

        getCompany = item => {
            if ( item.key ) { return item.name }
            if ( !item.domain || !item.domain.company ) { return item.company }
            return item.domain.company.name
        },

        getLink = item => {
            switch ( item.type ) {
                case 'email':
                    return `mailto:${item.contact}`
                case 'phone':
                    return `tel:${item.contact}`
                case 'other':
                default:
                    return item.contact
            }
        },
        archiveContact = e => {
            $saving( edit )
            adjust.track( 'contact_hide' )
            updateCard({
                id,
                shared_contacts: shared?.filter( contact => contact.id !== edit.id ).map( c => c.id )
            })
            discardContact( e )
        },
        hideContact = contact => {
            adjust.track( 'contact_hide' )
            updateCard({
                id,
                shared_contacts: shared?.filter( c => c.id !== contact.id ).map( c => c.id )
            })
        },
        showContact = contact => {
            adjust.track( 'contact_show' )
            updateCard({
                id,
                shared_contacts: [ ...( shared || []).map( c => c.id ), contact.id ].filter(( c, i, a ) => a.indexOf( c ) === i )
            })
        },
        selectSingle = contact => {
            $singleLoading( contact.id )
            updateCard({
                id,
                single_contact: contact.id
            })
        },
        saveContact = e => {
            if ( create ) {
                if ( editVal ) {
                    $saving( create )
                    adjust.track( 'contact_add' )
                    createContact({
                        type:    [ 'phone', 'email' ].includes( create.key ) ? create.key : 'other',
                        contact: editVal.contact.substr( 0, 4 ).toLowerCase() === 'http' || [ 'custom', 'phone', 'email' ].includes( create.key )
                            ? editVal.contact
                            : create.url.replace( '{{value}}', editVal.contact ),
                        subtype: editVal.subtype
                    })
                }
            } else {
                if ( edit.contact !== editVal ) {
                    $saving( edit )
                    updateContact({
                        id:      edit.id,
                        type:    edit.type,
                        contact: editVal.contact,
                        subtype: editVal.subtype
                    })
                }
            }
            discardContact( e )
        },
        discardTypes = e => {
            if ( e ) {
                e.preventDefault()
                e.stopPropagation()
            }

            $popup( false )
        },
        discardContact = e => {
            if ( e ) {
                e.preventDefault()
                e.stopPropagation()
            }

            $create( null )
            $editPopup( false )

            setTimeout(() => {
                $edit( null )
                $editVal( null )
            }, 200 )
        },
        openLink = ( item, noClick, noTrack ) => () => {
            if ( noClick ) { return }

            $opening( true )

            if ( noTrack ) {
                window.location.href = getLink( item )
                $opening( false )
            } else {
                request.get( `cards/${id}/contact?contact_id=${item.id}&format=json` ).then( event => {
                    ( event.status !== 200 ) && ( console.error( event.status, event.data ))
                    window.location.href = getLink( item )
                })
            }
        },
        contactLink = ( item, noClick ) => {

            const
                company = item.subtype || getCompany( item ) || ''

            return (
            /* eslint-disable-next-line */
                    <a className={!item.domain ? 'unverified' : ''} onClick={openLink( item, noClick, item.noTrack )}>
                    <span className="pcco-logo">
                        { getLogo( item, company ) }
                    </span>
                    <span className="pcco-content">
                        {
                            item === saving
                                ? (
                                        <Spinner />
                                    )
                                : (
                                        item.joinLink
                                            ? company
                                            : (
                                                    <>
                                                        <span>{item.contact}</span>
                                                        <span>{company}</span>
                                                    </>
                                                )
                                    )
                        }
                    </span>
                </a>
            )
        },
        setEdit = item => () => {
            $edit( item )
            $editPopup( true )
            $editVal( item )
        },
        itemView = handler => item => <li key={item.id}>{ ( handler || contactLink )( item ) }</li>,
        itemEdit = () => item =>
            (
                <li key={item.id} onClick={setEdit( item )}>
                    { contactLink( item, true ) }
                </li>
            ),
        contactsRender = ( data, handler ) =>
            (
                data?.shared_contacts
                    ?.filter( item => !item.is_deleted && ( !item.domain || ( !item.domain.is_invalid && !item.domain.is_restricted )))
                    ?.map( editable ? itemEdit( handler ) : itemView( handler ))
            )

    useEffect(() => {
        ( !!edit && !!create ) && ( $create( null ))
        //eslint-disable-next-line
    }, [edit])

    useEffect(() => {
        ( !!create && !!edit ) && ( $edit( null ))
        //eslint-disable-next-line
    }, [create])

    useEffect(() => {
        if ( popup || editPopup ) {
            document.body.classList.add( 'flow-locked' )
            document.documentElement.classList.add( 'flow-locked' )
        } else {
            document.body.classList.remove( 'flow-locked' )
            document.documentElement.classList.remove( 'flow-locked' )
        }
    }, [ popup, editPopup ])

    useEffect(() => {
        $singleLoading( false )
    }, [ data ])

    return (
        <section className="public-card-contacts">
            <h2>{
                editable
                    ? t( 'publicCard.MANAGE_PUBLIC_CONTACTS' )
                    : t( 'publicCard.QUICK_LINKS' )
            }</h2>
            <ul className={classNames( 'pcco-list', editable && 'editable' )}>
                {
                    !editable && (
                        <>
                            <li>{
                                contactLink({
                                    noTrack:  true,
                                    joinLink: true,
                                    contact:  `https://${config.api.noHttpUrl}/pb/${id}?referrer=pb_by_web`,
                                    domain:   {
                                        company: {
                                            name:        t( 'publicCard.ADD_TO_WALLET' ),
                                            square_logo: '/assets/icons/wallet.svg'
                                        }
                                    }
                                })
                            }</li>
                            <li>{
                                contactLink({
                                    noTrack:  true,
                                    joinLink: true,
                                    contact:  `https://${config.api.noHttpUrl}/card_vcf/${id}?referrer=web`,
                                    domain:   {
                                        company: {
                                            name:        t( 'publicCard.ADD_TO_CONTACT_BOOK' ),
                                            square_logo: '/assets/icons/contact.svg'
                                        }
                                    }
                                })
                            }</li>
                        </>
                    )
                }
                {
                    contactsRender( data )
                }
                {
                    create && (
                        <li key="create" className="no-link">
                            <span className="pcco-logo">
                                { getLogo( create, '' ) }
                            </span>
                            <span className="pcco-content">
                                <Spinner />
                            </span>
                        </li>
                    )
                }
                {
                    ( editable && !create ) && (
                        <Button className="add-contact-button" onClick={addContact}>
                            {
                                types
                                    ? t( 'publicCard.ADD_NEW_LINK' )
                                    : <Spinner />
                            }
                        </Button>
                    )
                }
            </ul>
            <ContactsTypePopup
                visible = { popup }
                sub = { editPopup }
                types = { types }
                contacts = { contacts }
                shared = { shared }

                singleLoading = { singleLoading }
                singleContact = { data.single_contact }

                onAdd = { addDraft }
                onCancel = { discardTypes }
                onHide = { hideContact }
                onShare = { showContact }
                onSelectSingle = { selectSingle }
            />
            <EditPopup
                visible = { editPopup }
                data = { edit || create }
                value = { editVal }
                prefix = { create ? create.url.split( '{{value}}' )[ 0 ] : null }

                onChange = { $editVal }
                onCancel = { discardContact }
                onSave = { saveContact }
                onArchive = { archiveContact }
            />
            {
                ( opening ) && (
                    <div className='center-container loading-overlay'>
                        <Spinner />
                    </div>
                )
            }
        </section>
    )
}

export default Contacts
