import { useState, useEffect } from 'react'
import classNames              from 'classnames'

import { Spinner, Icons } from 'components'
import { t }              from 'tools'
import config             from 'config'

import './contacts-type-popup.scss'

const
    getLogo = item => {
        if ( item.icon ) { return item.icon }
        if ( item.logo ) { return `${config.api.url}/media/${item.logo}` }
        if ( item.type === 'email' && !item.domain && !item.domain?.company ) { return <Icons.Mail /> }
        if ( item.type === 'phone' ) { return <Icons.Phone /> }
        if ( !item.domain || !item.domain.company ) { return <Icons.Link /> }

        const
            raw  = item.domain.company.square_logo,
            test = raw.slice( 0, 4 ).toLowerCase(),
            logo = test !== 'data' && test !== 'http' && test[ 0 ] !== '/'
                ? `${config.api.url}/media/${raw}`
                : raw

        return <img src={logo} alt="" />
    },

    RecommendContacts = ({ types, onAdd }) => {
        return (
            <div className="edit-contact-content">
                <h5>{t( 'publicCard.RECOMMENDED_CONTACTS' )}</h5>
                <ul className="contacts-type-list">
                    {
                        types.map( type => (
                            <li key={type.key} onClick={() => onAdd( type )}>
                                <span className="ctl-logo">
                                    <img
                                        src = {
                                            type.logo
                                                ? `${config.api.url}/media/${type.logo}`
                                                : '/assets/icons/unverified.svg'
                                        }
                                        alt=""
                                    />
                                </span>
                                <span className="ctl-content">
                                    {t( 'publicCard.ADD_TYPE_PREFIX' )} {t( `publicCard.${type.i18_key}` )}
                                </span>
                            </li>
                        ))
                    }
                </ul>
            </div>
        )
    },

    SingleRedirectPopup = ({ visible, current, loading, list, types, onSelect, onAdd, onCancel }) => {
        const
            disabled = {
                contact: null,
                domain:  null,
                id:      null,
                subtype: 'Disabled',
                type:    'other',
                icon:    <Icons.Add extraClassName='sr-disabled' />
            }

        return (
            <div className={classNames( 'contacts-type-popup', 'full-popup', visible && 'is-visible' )}>
                <div className='full-popup-header'>
                    <span className='full-popup-close' onClick={onCancel} />
                    <h4>{t( 'publicCard.MANAGE_CONTACTS' )}</h4>
                    <span className='full-popup-space' />
                </div>

                {
                    list.length > 0
                        ? (
                                <div className="edit-contact-content">
                                    <h5>{t( 'publicCard.CHOOSE_CONTACT_FOR_REDIRECT' )}</h5>
                                    <ul className="sr-list pcco-list">
                                        {
                                            [ disabled, ...list ].map( contact => (
                                                <li key={contact.id} onClick={() => onSelect( contact )} className={ current === contact.id ? 'sr-current' : ''}>
                                                    {/* eslint-disable-next-line */}
                                            <a href={null} className={!contact.domain ? 'unverified' : ''}>
                                                        <span className="pcco-logo">
                                                            {getLogo( contact )}
                                                        </span>
                                                        <span className="sr-content pcco-content">
                                                            <span>{contact.contact}</span>
                                                            <span>{contact.subtype}</span>
                                                        </span>
                                                        {
                                                            loading === contact.id
                                                                ? (
                                                                        <Spinner />
                                                                    )
                                                                : (
                                                                        <span className="sr-status">
                                                                            {
                                                                                current === contact.id
                                                                                    ? <Icons.Check />
                                                                                    : null
                                                                            }
                                                                        </span>
                                                                    )
                                                        }
                                                    </a>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            )
                        : (
                                <RecommendContacts
                                    types = { types }
                                    onAdd = { onAdd }
                                />
                            )
                }
            </div>
        )
    },

    ContactsTypePopup = ({ visible, sub, types, contacts, singleContact, singleLoading, shared, onShare, onHide, onAdd, onSelectSingle, onCancel }) => {
        const
            [ force, $force ] = useState( 0 ),
            [ loading, $loading ] = useState([]),
            [ redirect, $redirect ] = useState( false ),

            visibleContacts = contacts && Array.isArray( contacts ) ? contacts : [],
            visibleTypes = (
                contacts && Array.isArray( contacts )
                    ? (
                            ( types || []).filter( type => {
                                if ( type?.is_single ) {
                                    const
                                        alreadyShared = contacts.find( contact => contact.contact.indexOf( type.url.replace( '{{value}}', '' )) > -1 )

                                    return !alreadyShared
                                }

                                return true
                            })
                        )
                    : []
            ),
            forceUpdate = () => $force( force + 1 )

        useEffect(() => {
            $redirect( false )
        }, [ singleContact ])

        useEffect(() => {
            $loading([])
        }, [ shared ])

        useEffect(() => {
            if ( visible ) {
                document.body.classList.add( 'scroll-lock' )
                setTimeout(() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }, 200 )
            } else {
                document.body.classList.remove( 'scroll-lock' )
            }
        }, [ visible ])

        return (
            <>
                <div className={classNames( 'contacts-type-popup', 'full-popup', visible && 'is-visible', sub && 'is-lock' )}>
                    <div className='full-popup-header'>
                        <span className='full-popup-close' onClick={onCancel} />
                        <h4>{t( 'publicCard.MANAGE_CONTACTS' )}</h4>
                        <span className='full-popup-space' />
                    </div>

                    <div className="edit-contact-content">
                        <h5>{t( 'publicCard.SINGLE_REDIRECT' )}</h5>
                        <div className="single-redirect-wrap" onClick={() => $redirect( true )}>
                            {
                                singleContact?.id
                                    ? (
                                            <span className="pcco-logo sr-icon">
                                                { getLogo( singleContact ) }
                                            </span>
                                        )
                                    : (
                                            <span className="sr-icon">
                                                <Icons.SingleContact />
                                            </span>
                                        )
                            }
                            <span className="sr-content">
                                {t( 'publicCard.CHOOSE_CONTACT_FOR_REDIRECT' )}
                            </span>
                            <span className='sr-arrow'></span>
                        </div>
                    </div>

                    {
                        visibleContacts.length > 0 && (
                            <div className="edit-contact-content">
                                <h5>{t( 'publicCard.PREVIOUS_CONTACTS' )}</h5>
                                <ul className="contacts-archived-list pcco-list">
                                    {
                                        visibleContacts.map( contact => {
                                            const inShared = shared ? shared.find( c => c.id === contact.id ) : false

                                            return (
                                                <li
                                                    key = { contact.id }
                                                    className = { loading.includes( contact.id ) ? 'loading' : ( inShared ? 'shared' : 'unshared' )}
                                                    onClick = {() => {
                                                        $loading([
                                                            ...loading,
                                                            contact.id
                                                        ])
                                                        ;( inShared )
                                                            ? onHide( contact )
                                                            : onShare( contact )
                                                        forceUpdate()
                                                    }}
                                                >
                                                    {/* eslint-disable-next-line */}
                                                <a href={null} className={!contact.domain ? 'unverified' : ''}>
                                                        <span className="pcco-logo">
                                                            {getLogo( contact )}
                                                        </span>
                                                        <span className="pcco-content">
                                                            <span>{contact.contact}</span>
                                                            <span>{contact.subtype}</span>
                                                        </span>
                                                        {
                                                            loading.includes( contact.id ) && (
                                                                <Spinner />
                                                            )
                                                        }
                                                    </a>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        )
                    }
                    <RecommendContacts
                        types = { visibleTypes }
                        onAdd = { onAdd }
                    />
                </div>
                <SingleRedirectPopup
                    visible = { redirect }
                    current = { singleContact?.id || null }
                    list = { visibleContacts.filter( c => c.type === 'other' ) }
                    types = { visibleTypes }
                    loading = { singleLoading }

                    onAdd = { onAdd }
                    onSelect = { onSelectSingle }
                    onCancel = {() => $redirect( false )}
                />
            </>
        )
    }

export default ContactsTypePopup
