import { useState, useRef, useEffect } from 'react'
import classNames                      from 'classnames'
import { Input, Button }               from 'antd'

import { Icons, LabelIn } from 'components'
import { t }              from 'tools'
import config             from 'config'

const EditPopup = ({ visible, value, data, prefix, onArchive, onChange, onCancel, onSave }) => {
    const
        inputRef = useRef(),
        [ error, $error ] = useState( false ),
        getLogo = item => {
            if ( item?.logo ) { return `${config.api.url}/media/${item.logo}` }
            if ( !item?.domain || !item?.domain?.company ) { return '/assets/icons/unverified.svg' }

            const
                raw  = item.domain.company.square_logo,
                test = raw.slice( 0, 4 )

            return test !== 'data' && test !== 'http' && test[ 0 ] !== '/'
                ? `${config.api.url}/media/${raw}`
                : raw
        },
        logo = getLogo( data ),
        preSave = () => {
            if ( !!value.contact && ( !data.pattern || new RegExp( `^${data.pattern}$` ).test( value.contact ))) {
                onSave()
            } else {
                $error( true )
                inputRef.current.focus()
            }
        }

    useEffect(() => {
        if ( visible ) {
            setTimeout(() => {
                window.scrollTo({ top: 0, behavior: 'smooth' })
            }, 200 )
        }
    }, [ visible ])

    return (
        <div className={classNames( 'edit-contact-popup', 'full-popup', visible && 'is-visible' )}>
            <div className='full-popup-header'>
                <span className='full-popup-close' onClick={onCancel} />
                <h4>{ value?.id ? t( 'publicCard.EDIT' ) : `${t( 'publicCard.ADD_TYPE_PREFIX' )} ${t( `publicCard.${value?.type?.i18_key}` )}` }</h4>
                {
                    value?.id
                        ? (
                                <span className='full-popup-archive' onClick={onArchive}>
                                    <Icons.Archive />
                                </span>
                            )
                        : (
                                <span className='full-popup-space' />
                            )
                }
            </div>
            <div className="edit-contact-content">
                <span className="edit-contact-logo">
                    {
                        data?.logo
                            ? (
                                    <img src={`${config.api.url}/media/${data.logo}`} alt="" />
                                )
                            : (
                                    logo && (
                                        <img src={logo} alt="" />
                                    )
                                )
                    }
                </span>
                <Input
                    autoFocus
                    ref = { inputRef }
                    value = { value?.contact }
                    placeholder = { t( `publicCard.${data?.placeholder}` ) }
                    prefix = { prefix }
                    className = { error && 'has-error' }

                    onChange = { e => { $error( false ); onChange({ ...value, contact: data?.key === 'custom' ? e.target.value.toLowerCase() : e.target.value }) }}
                />
                <LabelIn label={`${t( 'publicCard.CONTACT_TITLE' )} ${t( 'publicCard.OPTIONAL' )}`}>
                    <Input
                        autoFocus
                        value = { value?.subtype }

                        onChange = { e => { $error( false ); onChange({ ...value, subtype: e.target.value }) }}
                    />
                </LabelIn>
                {
                    data?.signup && (
                        <p className='signup-link'>If you don&apos;t have account you can <a href={data?.signup} target="_blank" rel="noreferrer">register here</a>.</p>
                    )
                }
                <div className="edit-contact-actions">
                    <Button type="primary" onClick={preSave}>
                        {
                            value?.id
                                ? t( 'publicCard.SAVE_CHANGES' )
                                : t( 'publicCard.SAVE_CONTACT' )
                        }
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default EditPopup
