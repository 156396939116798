import { Component }                 from 'react'
import { Switch, Route, withRouter } from 'react-router-dom'
import Adjust                        from '@adjustcom/adjust-web-sdk'
import { LinkedInCallback }          from 'react-linkedin-login-oauth2'
import TabeeAuth                     from '@tabee/frontend-auth'

import { PublicCard, DigitalWallet, AuthCheck } from 'pages'
import { users, request, i18next }              from 'tools'
import config                                   from 'config'

import connector from './appConnector'
import './app.scss'
import './styles/index.scss'

class App extends Component {

    constructor ( props ) {
        super( props )

        TabeeAuth.setApi( config.api.url )
        TabeeAuth.setSSO( config.sso.url )
        TabeeAuth.setProject( config.api.project )
    }

    componentDidMount () {
        const
            id = request.storage.get( 'user_id' )

        ;( id ) && ( this.props.getSelfUser())

        i18next.on( 'languageChanged', () => this.forceUpdate())
    }

    componentDidUpdate ( prevProps ) {
        const
            { user, current, account, companies } = this.props

        if ( user !== prevProps.user && !!user ) {
            Adjust.addGlobalCallbackParameters([
                { key: 'userID', value: user.id },
                { key: 'language_local', value: i18next.language },
                { key: 'language', value: window.navigator.language },
                { key: 'email', value: user.email }
            ])

            ;( !current && account === user.id ) && ( this.props.setAccount( account ))
            ;( !!account && account !== 'undefined' ) && ( users.load( this.props ))
        }

        (
            ( companies !== prevProps.companies || user !== prevProps.user ) &&
            companies && user &&
            !current && !!account && account !== 'undefined' &&
            account !== user.id
        ) && (
            this.props.setAccount( account )
        )
    }

    render () {
        return (
            <div className="app-page">
                <Switch>
                    <Route exact path="/" component={() => {
                        window.location.href = config.indexPage
                        return null
                    }} />
                    <Route exact path="/u">
                        <PublicCard />
                    </Route>
                    <Route exact path="/u/:id">
                        <PublicCard />
                    </Route>
                    <Route exact path="/p/:id">
                        <PublicCard />
                    </Route>
                    <Route exact path="/found/:id">
                        <PublicCard />
                    </Route>
                    <Route exact path="/digital/:id">
                        <PublicCard />
                    </Route>
                    <Route exact path="/u/:id/*">
                        <PublicCard />
                    </Route>
                    <Route exact path="/p/:id/*">
                        <PublicCard />
                    </Route>
                    <Route exact path="/found/:id/*">
                        <PublicCard />
                    </Route>
                    <Route exact path="/digital/:id/*">
                        <PublicCard />
                    </Route>
                    <Route exact path="/digital">
                        <DigitalWallet />
                    </Route>
                    <Route exact path="/auth/:mode">
                        <AuthCheck />
                    </Route>
                    <Route exact path="/linkedin" component={LinkedInCallback} />
                </Switch>
            </div>
        )
    }
}

export default connector( withRouter( App ))
