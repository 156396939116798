/* IMPORT */
import { connect }     from 'react-redux'
import { userActions } from 'services'

/* EXPORT */

const
    mapStateToProps = state => ({
        account:   state.user.account,
        companies: state.user.companies,
        current:   state.user.current,
        user:      state.user.info
    }),

    allActions = { ...userActions }

export default connect( mapStateToProps, allActions )
